<!-- 订单统计 -->
<template>
	<div :class="themeClass" class="capitalBill">
		<div class="top flex-row-space-between-center margin-t-30">
			<div class="title font-size18 font-weight700 font-color-333">采购账单</div>
			<div :loading="downloadLoading" style="cursor: pointer;" class="topBtn  text-center  excelBtn"
			 @click="ExportEXcel">
				<div class="img1"></div>
				<div style="display: inline-block;">账单下载</div>
			 </div>
		</div>
		<div class="flex-row-align-center">
			<span style="width: 50px;">
				店铺
			</span>
			<el-select  v-model="fCompanyId" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="CompanyChange">
				<el-option v-for="(item,index) in CompanyNamelist" :key="index" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
      <div class="choose-date">
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
        </div>
			<!-- <span :class="activeName=='yearMonth'?'activeClass':''" style="width: 50px;">
				年月
			</span>
			<el-select  v-model="balance" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="monthChange" >
				<el-option v-for="(item,index) in balanceOptions" :key="index" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div class="tabs">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane class="pointer-color-theme" label="昨天" name="yesterday"></el-tab-pane>
					<el-tab-pane class="pointer-color-theme" label="今天" name="today"></el-tab-pane>
				</el-tabs>
			</div>
			<div class="inputBor">
				<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期"></el-date-picker>
				<span>至</span>
				<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期"></el-date-picker>
			</div> -->
			<div class="pointer topBtn font-size13 bg-color-theme text-center font-color-fff queryBtn margin-l-10"
				@click="query">查询</div>
			
		</div>
		<div v-if='searchShow'   class="flex-row-align-center" style="margin-top: 10px;">
			<el-input prefix-icon="el-icon-search" v-model.trim="searchText" size="medium" placeholder="请输入商品名称,仓库名称模糊查询"
				clearable />
		</div>
		<div v-if='!searchShow'   class="flex-row-align-center" style="margin-top: 10px;">
			<el-input prefix-icon="el-icon-search" v-model.trim="searchTexts" size="medium" placeholder="请输入商品名称,仓库名称模糊查询"
				clearable />
		</div>
		<div class="tab margin-t-13 flex-row margin-b-10">
			<div class="flex-row">
				<div class="tabItem text-center" :class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2' : ''"
					@click="tabClick(0)">汇总账单</div>
				<div class="tabItem text-center" :class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2' : ''"
					@click="tabClick(1)">账单明细</div>
			</div>
			<div class="tab-right"></div>
		</div>
		<!-- <div v-if="billWalletSummary.length==0&&moneyDashSummary.length==0&&accountPaySummary.length==0" class="flex-row-center-center"> -->
		<!-- <svg-icon icon-class="NoDataAvailable" /> -->
		<!-- </div> -->

		<div v-loading="loading" v-show="tabSelect == 0" class="tab-content2">
			<el-table :data="tableData" stripe border :row-style="{ height: '36px' }"
				:header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }"
				@row-click="hangdianji">
				<!-- <el-table-column prop="fShopName" label="店铺" width="220" align="left" show-overflow-tooltip> -->
				</el-table-column>
				<el-table-column prop="fDepotName" label="仓库" width="165" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fGoodsFullName" label="商品" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fPlanAmount" label="订单量" width="120" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fScheduleVanAmount" label="提货量" width="120" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fSendVanAmount" label="可派车量" width="120" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fRefundAmount" label="已退款量" width="120" align="right" show-overflow-tooltip></el-table-column>
			</el-table>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
					:currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		<div v-loading="loading" v-show="tabSelect == 1" class="tab-content2">
			<el-table :data="tableDatadetails" stripe border :row-style="{ height: '36px' }"
				:header-cell-style="{ 'text-align': 'center', background: '#e9f3ff', 'font-weight': '500', color: '#000', 'font-size': '13px' }">
				<el-table-column prop="fBusinessStatusName" label="状态" width="110" align="center" show-overflow-tooltip
					fixed></el-table-column>
				<!-- <el-table-column prop="fShopName" label="店铺" width="160" align="left" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="fOrderNumber" label="订单编码" width="165" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fGoodsFullName" label="商品" width="200" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fDepotName" label="仓库" width="150" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fTradingModel" label="交易模式" width="110" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fPlanAmount" label="订单量" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fScheduleVanAmount" label="已提货量" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fSendVanAmount" label="可派车量" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fRefundAmount" label="已退款量" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fcGoodsPrice" label="挂牌价(元)" width="120" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fSettlePrice" label="结算单价(元)" width="120" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fPointMoney" label="积分抵扣金额(元)" width="130" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fCouponMoney" label="优惠券抵扣金(元)" width="130" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fGatheringMoney" label="实际支付金额(元)" width="130" align="right" show-overflow-tooltip></el-table-column>
			</el-table>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
					:currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue';
  import Timequery from "@/components/Timequery.vue"
	export default {
		components: {
			pagination,
      Timequery
		},
		props: {},
		data() {
			return {
				fCompanyId: 5,
				CompanyNamelist: [],// 店铺列表
				balance:'',
				balanceOptions: [],
				startMouth:'',
				endMouth:'',
				searchTexts:'',
				searchText:'',
				activeName: 'today',
				searchDate: ['', ''],
				loading: false,
				defaultTime: '', //时间默认值
				value: 0, //钱包类型下拉框v-model绑定的值
				typeSelect: 0, //钱包类型value
				value1: [new Date(), new Date()], //时间
				fBeginTime: '',
				fEndTime: '',
				tabSelect: 0, //tab选中项

				tableData: [], //订单列表表格数据
				requestUrl: '', //订单列表接口url
				downloadLoading: false,
				total: 0, //总条数
				pagesize: 20, //每页显示条目个数
				currentPage: 1, //当前页
				columnList: [], //导出表头列表
				detailsforms: {},
				tableDatadetails: [],
				tableblesdetail:[
					
					
					{
						prop: 'fBusinessStatusName',
						label: '状态',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fShopName',
						label: '店铺',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fOrderNumber',
						label: '订单编码',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGoodsFullName',
						label: '商品',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDepotName',
						label: '仓库',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fTradingModel',
						label: '交易模式',
						width: '120',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fPlanAmount',
						label: '订单量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fScheduleVanAmount',
						label: '已提货量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fSendVanAmount',
						label: '可派车量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fRefundAmount',
						label: '已退款量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fcGoodsPrice',
						label: '挂牌价',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fSettlePrice',
						label: '结算单价',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fPointMoney',
						label: '积分抵扣金额',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fCouponMoney',
						label: '优惠券抵扣金',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGatheringMoney',
						label: '实际支付金额',
						width: '200',
						align: 'left',
						fixed: true
					},
				],
				tablebles: [
					{
						prop: 'fShopName',
						label: '店铺',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGoodsFullName',
						label: '商品',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDepotName',
						label: '仓库',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fPlanAmount',
						label: '订单量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fScheduleVanAmount',
						label: '提货量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fSendVanAmount',
						label: '可派车量',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fRefundAmount',
						label: '已退款量',
						width: '200',
						align: 'left',
						fixed: true
					},
				],
				mingxiids: "",
				mingxicangkuids: "",
				searchShow:true,
			};
		},
		created() {
			this.getdayValue();
		},
		mounted() {
			this.acquireMonth();
			// const start = new Date();
			// const end = new Date();
			// // this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90));
			// this.searchDate[0] = end;
			// this.searchDate[1] = end;
			// console.log('this.value1', this.value1);

		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		filters: {
			//   取绝对值
			abs(val) {
				return Math.abs(val);
			}
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        this.getDetails();
		  	this.acquireDate();
	   		this.getShopList()
      },
			// 获取店铺列表
			getShopList() {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/get-shoping-bill-statistics-shop", {
				}).then(
					(res) => {
						if (res.obj) {
							let obj = {}
							res.obj.forEach(item => {
								this.fCompanyId = item.fShopID
								obj.label = item.fShopName
								obj.value = item.fShopID 
								this.CompanyNamelist.push(obj)
							})
						}
						console.log(this.CompanyNamelist)
					},
					(error) => {}
				);
			},
			// 店铺筛选
			CompanyChange() {
				this.getDetails()
			},
			//获取当前时间的前12个月
			dateAcquire(){
				    var dataArr = [];
				    var data = new Date();
				    var year = data.getFullYear();
				    data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
				    for (var i = 0; i < 12; i++) {
				        data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
				        var m = data.getMonth() + 1;
				        m = m < 10 ? "0" + m : m;
						dataArr.push({
							value: data.getTime(),
							label: data.getFullYear() + "年" + (m) + "月"
						})
				    }
					this.balanceOptions = JSON.parse(JSON.stringify(dataArr))
			},
			//时间更改
			monthChange(e){
				this.activeName = "yearMonth"
				this.searchDate[0] = this.getMonthFirst(e)
				this.searchDate[1] = this.getMonthLast(e)
				this.getDetails()
			},
			//格式化时间
			getDateNoDay(time) {
			  if (time == null || time == '') {
			    return '';
			  }
			  let date = new Date(time);
			  let year = date.getFullYear(); //年
			  let month = date.getMonth() + 1; //月
			  let day = date.getDate(); //日
			  month = month > 9 ? month : '0' + month;
			  day = day > 9 ? day : '0' + day;
			  return `${year}年${month}月`;
			},
			//获取某个月的第一天
			getMonthFirst(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth(), 1)
			 	return enddate
			},
			//获取某个月的最后一天
			getMonthLast(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth() + 1, 0)
			 	return enddate
			},
			acquireDate(){
				this.dateAcquire()
			},
			acquireMonth(){
				this.ApiRequestPostNOMess("api/mall/sys/account-date/get-curret-date", {
				}).then(
					(res) => {
					// console.log(res,'89898989898')
					this.startMouth = res.obj.fStartDate
					this.endMouth = res.obj.fEndDate
					},
					(error) => {}
				);
			},
			// 统计报表行点击事件
			hangdianji(row) {
				// 统计报表行点击事件
				console.log('行数据', row)
				if (row.fShopName == '合计' || row.fShopName == '本页小计') {} else {
					// this.detailsforms= {
					// 	fGoodsID: row.fGoodsID,
					// 	fDepotID: row.fDepotID
					// }
					this.value = 1;
					this.mingxiids = row.fGoodsID;
					this.mingxicangkuids = row.fDepotID;
					this.getDetails();
				}
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', ''];
				}
				const start = new Date();
				const end = new Date();
				this.balance = "";
				switch (this.activeName) {
					case 'all':
						this.searchDate[0] = '';
						this.searchDate[1] = '';
						break;
					case 'today':
						this.searchDate[0] = start.setTime(start.getTime());
						this.searchDate[1] = end;
						break;
					case 'yesterday':
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24));
						this.searchDate[1] = this.searchDate[0];
						break;
					case 'oneMonth':
						this.searchDate[0] = this.startMouth;
						this.searchDate[1] = this.endMouth;
						break;
				}
				this.currentPage = 1;
				this.getDetails();
			},

			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getDetails();
			},
			getdayValue() {
				let a = new Date();
				this.defaultTime = this.getDate(a);
			},
			// tab切换
			tabClick(val) {
				if(val == 0){
					this.searchShow = true
				}else{
					this.searchShow = false
				}
				if(this.tabSelect == 0){
					this.mingxiids = ''
				}
				this.tabSelect = val;
				this.value = val;
				this.currentPage = 1;
				this.getDetails();
			},
			// 点击查询
			query() {
				this.getDetails();
			},
			// 点击
			cardClick(val) {
				this.value = val;
				this.getDetails();
				this.tabClick(1);
			},
			// 获取列表数据
			getDetails() {
				this.loading=true;
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				let parms = {}
				if (this.value == 0) {
					this.requestUrl = 'api/mall/ebsale/order/get-list-by-customerID';
					parms = {
						fShopID: this.fCompanyId,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						skipCount: (this.currentPage - 1) * this.pagesize,
						filter:this.searchText,
						sorting: '',
						wheres: ''
					}
				}
				if (this.value == 1 && this.mingxiids == '') {
					this.requestUrl = '/api/mall/ebsale/order/get-listdetail-by-customerID';
					parms = {
						fShopID: this.fCompanyId,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						filter:this.searchTexts,
						sorting: '',
						skipCount: (this.currentPage - 1) * this.pagesize,
						wheres: ''
					}
				}
				if (this.value == 1 && this.mingxiids != '') {
					this.requestUrl = '/api/mall/ebsale/order/get-listdetail-by-customerID';
					parms = {
						fShopID: this.fCompanyId,
						fGoodsID: this.mingxiids,
						fDepotID: this.mingxicangkuids,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						filter: '',
						sorting: '',
						skipCount: (this.currentPage - 1) * this.pagesize,
						wheres: ''
					}
				}
				this.ApiRequestPostNOMess(this.requestUrl, parms).then(
					result => {
						console.log('获取统计报表数据', result)
						this.tableData = [];
						result.obj.items.forEach(item => {
							item.fBusinessTime = this.getTime(item.fBusinessTime);
							item.fTareTime = this.getTime(item.fTareTime);
							item.fGrossTime = this.getTime(item.fGrossTime);
							item.fKeepDate = this.getDate(item.fKeepDate);
							if(item.fPlanAmount){
								item.fPlanAmount = this.NumFormat(item.fPlanAmount.toFixed(2))
							}
							if(item.fScheduleVanAmount){
								item.fScheduleVanAmount = this.NumFormat(item.fScheduleVanAmount.toFixed(2))
							}
							if(item.fSendVanAmount){
								item.fSendVanAmount = this.NumFormat(item.fSendVanAmount.toFixed(2))
							}
							if(item.fRefundAmount){
								item.fRefundAmount = this.NumFormat(item.fRefundAmount.toFixed(2))
							}
							if(item.fCGoodsPrice){
								item.fCGoodsPrice = this.NumFormat(item.fCGoodsPrice.toFixed(2))
							}
							if(item.fGoodsPrice){
								item.fGoodsPrice = this.NumFormat(item.fGoodsPrice.toFixed(2))
							}
							if(item.fPointMoney){
								item.fPointMoney = this.NumFormat(item.fPointMoney.toFixed(2))
							}
							if(item.fCouponMoney){
								item.fCouponMoney = this.NumFormat(item.fCouponMoney.toFixed(2))
							}
							if(item.fGatheringMoney){
								item.fGatheringMoney = this.NumFormat(item.fGatheringMoney.toFixed(2))
							}
							if(item.fSettlePrice){
								item.fSettlePrice = this.NumFormat(item.fSettlePrice.toFixed(2))
							}
							if(item.fcGoodsPrice){
								item.fcGoodsPrice = this.NumFormat(item.fcGoodsPrice.toFixed(2))
							}
							
							
						});
						
						if (this.value == 1) {
							this.tabSelect = 1;
							this.tableDatadetails = result.obj.items;
							this.total = result.obj.totalCount
							this.$forceUpdate();
						}else{
							this.tableData = result.obj.items;
							this.total = result.obj.totalCount
							this.$forceUpdate();
						}
                       this.loading=false;

					},
					rej => {
						this.loading=false;
					}
				);
			},

			// EXcel导出
			ExportEXcel() {
				this.downloadLoading = true;
				console.log('列表数据', this.tablebles);
				this.columnList = [];
				let parmsexp = {};
				if(this.tabSelect == 0){
					for (var i = 0; i < this.tablebles.length; i++) {
						this.columnList.push({
							Key: this.tablebles[i].prop,
							Value: this.tablebles[i].label
						});
					}
					if (this.searchDate.length > 1) {
						if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
							return this.$message('结束时间不能早于开始时间');
						}
					}
					if (this.tableData.length == 0) {
						return this.$message('导出数据不能为空！');
					}
					parmsexp = {
						fColumnList: this.columnList,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						skipCount: (this.currentPage - 1) * this.pagesize,
						filter:"",
						wheres:"",
						sorting:"",
					};
					this.requestUrl = 'api/mall/ebsale/order/down-list-by-customerID';
				}
				if(this.tabSelect == 1 && this.mingxiids != ''){
					for (var i = 0; i < this.tableblesdetail.length; i++) {
						this.columnList.push({
							Key: this.tableblesdetail[i].prop,
							Value: this.tableblesdetail[i].label
						});
					}
					if (this.searchDate.length > 1) {
						if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
							return this.$message('结束时间不能早于开始时间');
						}
					}
					if (this.tableDatadetails.length == 0) {
						return this.$message('导出数据不能为空！');
					}
					parmsexp = {
						fGoodsID: this.mingxiids,
						fDepotID: this.mingxicangkuids,
						fColumnList: this.columnList,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						skipCount: (this.currentPage - 1) * this.pagesize,
						filter: '',
						wheres:"",
						sorting:"",
					};
					this.requestUrl = 'api/mall/ebsale/order/down-listdetail-by-customerID';
				}
				if(this.tabSelect == 1 && this.mingxiids == ''){
					for (var i = 0; i < this.tableblesdetail.length; i++) {
						this.columnList.push({
							Key: this.tableblesdetail[i].prop,
							Value: this.tableblesdetail[i].label
						});
					}
					if (this.searchDate.length > 1) {
						if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
							return this.$message('结束时间不能早于开始时间');
						}
					}
					if (this.tableDatadetails.length == 0) {
						return this.$message('导出数据不能为空！');
					}
					parmsexp = {
						fColumnList: this.columnList,
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						skipCount: (this.currentPage - 1) * this.pagesize,
						filter: '',
						wheres:"",
						sorting:"",
					};
					this.requestUrl = 'api/mall/ebsale/order/down-listdetail-by-customerID';
				}
				this.ApiRequestPostNOMess(this.requestUrl, parmsexp).then(
					result => {
						console.log('获取EXcel导出数据', result);
						window.open(result.obj);
						this.downloadLoading = false;
					},
					rej => {
						this.downloadLoading = false;
					}
				);
			},
			//千分符
			   NumFormat(value) {
			    if (!value) return '0.00';
			    value = Number(value).toFixed(2).toString();
			    var intPart = parseInt(Number(value)); //获取整数部分
			    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
			    var floatPart = ".00"; //预定义小数部分
			    var value2Array = value.split(".");
			    //=2表示数据有小数位
			    if (value2Array.length == 2) {
			     floatPart = value2Array[1].toString(); //拿到小数部分
			     if (floatPart.length == 1) { //补0,实际上用不着
			      return intPartFormat + "." + floatPart + '0';
			     } else {
			      return intPartFormat + "." + floatPart;
			     }
			    } else {
			     return intPartFormat + floatPart;
			    }
			   }
		},
    // aa(callback, vc) {
    //   if (!callback) {
		// 			console.log(vc)
		// 		} 
    // }
	};
</script>

<style lang="scss" scoped>
  .choose-date {
    width: 406px;
    display: flex;
    align-items: center;
  }
	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		width: 280px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background: themed('themes_color');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.margin-t-8 {
		margin-top: 8px;
	}

	.capitalBill {
		width: 921px;
	}

	.top {
		width: 100%;
		height: 32px;
	}

	.selectBox {
		width: 121px;
		margin-left: 15px;
	}

	.chooseDate {
		width: 354px;
	}

	.excelBtn {
		margin-left: 6px;
		width: 120px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		color: #000;
	    margin-bottom: 10px;
	    font-size: 16px;
	}

	.queryBtn {
		width: 75px;
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	.selectBox {
		/deep/ .el-input__inner {
			color: #666;
		}
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	.el-select__caret .el-input__icon .el-icon-arrow-up {
		line-height: 32px;
	}

	.flex1 {
		flex: 1;
	}

	.tab {
		width: 100%;
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tabItem {
		width: 108px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.borderTab {
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tab-content1 {
		width: 100%;
	}

	.tab-content1-item {
		width: 49%;
		height: 135px;
		border-radius: 2px;
		box-sizing: border-box;
		background-color: #f7fbff;
		margin-bottom: 10px;

		&:nth-child(2n) {
			margin-left: 10px;
		}
	}

	.left {
		flex: 1;
		height: 104px;
	}

	.right {
		width: 156px;
		height: 104px;
		margin-right: 5px;
		// background: url(../../../assets/u248.png) no-repeat;
		background-size: contain;
		background-position: center;
	}

	.tab-content2 {
		width: 100%;
	}

	/deep/ .el-table td {
		padding: 0;
	}

	/deep/ .el-table th {
		height: 36px;
		padding: 0;
	}
	.activeClass{
		width: 50px;
		height: 32px;
		background-color: #0173FE;
		color:white;
		line-height: 32px;
		text-align: center;
	}
	.img1 {
		background-image: url('../../assets/excel.png');
		width: 40px;
		height: 40px;
		display: inline-block;
		background-size: 100% 100%;
		vertical-align: middle;
	}
</style>
